export const checksums = {
  "content": "v3.2.0--lQWYzfm8Xa"
}

export const tables = {
  "content": "_content_content",
  "info": "_content_info"
}

export default {
  "content": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "date": "date",
      "showTitle": "string",
      "lastmod": "date",
      "tags": "json",
      "versions": "json",
      "rawbody": "string"
    }
  },
  "info": {
    "type": "data",
    "fields": {}
  }
}